import React, { useMemo, useState, useEffect } from 'react'
import {
  Wrapper,
  Content,
  Details,
  DetailsLeft,
  DetailsRight,
  RoundInfo,
  PriceWrapper,
  UsefulInfo,
  InfoItem,
  InfoItemEnd,
  SocialLinks,
  SocialLink,
  AboutWrapper,
  EventsWrapper,
  MediaGalleryWrapper,
  MediaGalleryMainImage,
  MediaGalleryImageContainer,
  MediaGalleryImageOverlay
} from './styled'
import { useStoreon } from 'storeon/react'
import { getPrefs } from 'apis'
import { useParams } from 'react-router-dom'
import { genImagePath } from 'utils'
import Banner from './Banner'
import MediaGallery from './MediaGallery'
import { Icon, EventCard, Carousel, Loader, SearchModal } from 'components'
import { useQuery, useLazyQuery, gql } from '@apollo/client'
import { format } from 'date-fns'

const GET_EVENT = gql`
  query getevent($limit: Int!, $where: events_bool_exp = {}) {
    events(limit: $limit, order_by: { start_date: asc }, where: $where) {
      id
      is_digital
      location
      end_date
      cover_image_id
      artists
      about
      major_city
      max_price
      min_price
      name
      netflix_shows
      scene
      start_date
      target_audience
      ticket_link
      venue {
        id
        name
        major_city_id
        partner_plan_id
        slug
        instagram
        facebook
        youtube
        website
        phone
        email
        twitter
      }
      event_images {
        id
        name
      }
      event_tags {
        tag {
          name
        }
      }
    }
  }
`

const GET_MORE_EVENTS = gql`
  query getmoreevents($limit: Int, $where: events_bool_exp = {}) {
    events(limit: $limit, order_by: { start_date: desc }, where: $where) {
      id
      location
      end_date
      cover_image_id
      about
      major_city
      min_price
      name
      slug
      start_date
      venue_id
      event_images {
        id
        name
      }
      event_tags {
        tag {
          name
        }
      }
    }
  }
`

const EventDetails = (props) => {
  const { isMobile, showSearchModal } = useStoreon(
    'isMobile',
    'showSearchModal'
  )
  const { slug } = useParams()
  const { user } = useStoreon('user')
  const newDate = format(new Date(), 'P')
  const [showGallery, setShowGallery] = useState(false)
  const [userCities, setUserCities] = useState([])

  useEffect(() => {
    ;(async () => {
      if (user) {
        const token = await user.getIdToken()
        const data = await getPrefs({ token })
        setUserCities(data.cities)
      }
    })()
  }, [user])

  const { loading: eventsLoading, data: eventData } = useQuery(GET_EVENT, {
    variables: {
      limit: 1,
      where: {
        slug: {
          _eq: slug
        }
      }
    }
  })
  const tags = eventData?.events[0].event_tags?.map((t) => t.tag.name)

  const event = eventData?.events
  const {
    id,
    about,
    artists,
    is_digital: isDigital,
    max_price: maxPrice,
    min_price: minPrice,
    netflix_shows: netflixShows,
    cover_image_id: coverImageId,
    scene,
    target_audience: targetAudience,
    ticket_link: ticketLink,
    location,
    event_images: eventImages,
    major_city: majorCity,
    venue
  } = event?.[0] || {}

  const isPaidVenue =
  venue && (venue.partner_plan_id !== null && venue.partner_plan_id >= 2)
    ? true
    : false

  const [
    getEventsByVenue,
    { loading: eventsByVenueLoading, data: eventsByVenueData }
  ] = useLazyQuery(GET_MORE_EVENTS)

  useEffect(() => {
    if (venue) {
      getEventsByVenue({
        variables: {
          limit: 12,
          where: {
            venue_id: {
              _eq: venue?.id
            },
            end_date: {
              _gte: newDate
            }
          }
        }
      })
    }
  }, [venue])

  const [getMoreEvents, { loading: moreEventsLoading, data: moreEventsData }] =
    useLazyQuery(GET_MORE_EVENTS, {
      variables: {
        limit: 12,
        where: {
          venue_id: isPaidVenue ? {
                _eq: venue.id
              }
            : undefined,
          major_city: userCities.length
            ? {
                _in: userCities
              }
            : majorCity
            ? {
                _in: [majorCity]
              }
            : undefined,
          event_tags:
            !isPaidVenue && tags && tags.length && !!tags[0]
              ? {
                  tag: {
                    name: {
                      _in: tags
                    }
                  }
                }
              : undefined,
          end_date: {
            _gte: newDate
          }
        }
      }
    })

  useEffect(() => {
    if (eventData) {
      getMoreEvents()
    }
  }, [eventData])

  const moreEvents = moreEventsData?.events

  const loading = useMemo(() => {
    if (venue) {
      if (eventsLoading) {
        return true
      }
    } else {
      if (!event) {
        return true
      }
    }

    return false
  }, [eventsLoading, event])

  const eventCarouselLoading = useMemo(() => {
    if (venue) {
      if (
        moreEventsLoading ||
        eventsByVenueLoading ||
        !moreEvents ||
        !eventsByVenueData
      ) {
        return true
      }
    } else {
      if (moreEventsLoading || !moreEvents) {
        return true
      }
    }

    return false
  }, [moreEventsLoading, eventsByVenueLoading, moreEvents, eventsByVenueData])

  // if (!eventData.name) {
  //   history.push('/not-found')
  //   return null
  // }

  const eventsByVenue = eventsByVenueData?.events

  let allImages = []

  if (eventImages) {
    allImages = eventImages.map((i) => {
      const imageUrl = genImagePath({
        ownerType: 1,
        ownerId: id,
        name: i.name
      })

      return {
        isCover: i.id === coverImageId,
        imageUrl
      }
    })
  }

  const renderSocialLink = (item, idx) => {
    const { to, svg } = item

    if (!to) {
      return null
    }

    if (svg === 'twitter') {
      return (
        <SocialLink key={idx} href={to} target="_blank">
          <Icon name='newTwitterIcon' />
        </SocialLink>
      )
    }
    
    return (
      <SocialLink key={idx} href={to} target="_blank">
        <Icon name={`${svg}`} />
      </SocialLink>
    )
  }

  const socialLinks = () => {
    const links = [
      { to: venue?.facebook, svg: 'facebook' },
      { to: venue?.instagram, svg: 'instagram' },
      { to: venue?.twitter, svg: 'twitter' },
      { to: venue?.youtube, svg: 'youtube' },
      { to: venue?.email ? `mailto:${venue?.email}` : undefined, svg: 'email' }
    ]

    return <SocialLinks>{links.map(renderSocialLink)}</SocialLinks>
  }

  const renderInfo = (item, idx) => {
    const { data, svg, link, href } = item

    if (!data) {
      return null
    }

    return (
      <InfoItem key={idx}>
        <Icon name={svg} />
        {link ? (
          <a href={href} target="_blank" rel="noreferrer">
            {data}
          </a>
        ) : (
          <span>{data}</span>
        )}
      </InfoItem>
    )
  }

  const infoData = [
    {
      data: `${
        isDigital !== null ? (isDigital ? 'Digital' : 'Live') : 'Live/Digital'
      } Event`,
      svg: 'theater'
    },
    {
      data: isPaidVenue ? venue.name : location,
      svg: isPaidVenue ? 'venue' : 'place',
      link: isPaidVenue,
      href: isPaidVenue ? `/venue/${venue.slug}` : ''
    },
    { data: scene, svg: 'performance' },
    { data: targetAudience, svg: 'audience' },
    { data: venue?.phone, svg: 'phone' },
    { data: venue?.website, href: venue?.website, svg: 'external', link: true }
  ]

  const renderRight = () => {
    const isSinglePrice = minPrice === maxPrice
    const money = majorCity === 6 ? '£' : '$'

    const priceString = isSinglePrice
      ? `${money}${minPrice}`
      : `${money}${minPrice} - ${money}${maxPrice}`

    if (isMobile) {
      return (
        <DetailsRight>
          <RoundInfo>
            <UsefulInfo>
              <InfoItemEnd>
                <h3>Useful Info</h3>
              </InfoItemEnd>
              {infoData.map(renderInfo)}
              <InfoItemEnd>{socialLinks()}</InfoItemEnd>
            </UsefulInfo>
          </RoundInfo>
        </DetailsRight>
      )
    }

    return (
      <DetailsRight>
        <RoundInfo>
          <PriceWrapper>
            <div>
              <h2>Price{!isSinglePrice && ' Range'}</h2>
              <h3>{priceString}</h3>
            </div>
            <a href={ticketLink} rel="noreferrer" target="_blank">
              Get Tickets
            </a>
          </PriceWrapper>
        </RoundInfo>
        <RoundInfo>
          <UsefulInfo>
            <InfoItemEnd>
              <h3>Useful Info</h3>
            </InfoItemEnd>
            {infoData.map(renderInfo)}
            <InfoItemEnd>{socialLinks()}</InfoItemEnd>
          </UsefulInfo>
        </RoundInfo>
      </DetailsRight>
    )
  }

  const RenderDetails = ({ handleOpenGallery }) => {
    return (
      <Details>
        <DetailsLeft>
          <h2>About the event</h2>
          <AboutWrapper>
            {about
              ?.split('\n')
              .map((i, idx) => (i ? <p key={idx}>{i}</p> : <br />))}
          </AboutWrapper>
          {netflixShows && (
            <>
              <h3>Similar to these shows</h3>
              <p>{netflixShows}</p>
            </>
          )}
          {artists && (
            <>
              <h3>Artists</h3>
              <p>{artists}</p>
            </>
          )}
          {isPaidVenue && (
            <MediaGalleryWrapper>
              <h1>Media Gallery</h1>
              <MediaGalleryImageContainer onClick={() => handleOpenGallery()}>
                <MediaGalleryMainImage src={allImages[0].imageUrl} />
                <MediaGalleryImageOverlay />
              </MediaGalleryImageContainer>
            </MediaGalleryWrapper>
          )}
        </DetailsLeft>
        {renderRight()}
      </Details>
    )
  }

  const renderEvent = (item, idx) => {
    return <EventCard key={idx} data={item} />
  }

  const carouselLabel = isPaidVenue
    ? `More events from ${venue.name}`
    : 'More events like this'

  const carouselEvents = isPaidVenue ? eventsByVenue : moreEvents

  const renderMoreLikeThis = () => {
    if (eventCarouselLoading) {
      return (
        <div
          style={{
            height: '100%',
            margin: 'auto',
            fontSize: '20px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <p>Loading</p>
          <div style={{ marginLeft: '10px' }}>
            <Loader />
          </div>
        </div>
      )
    }

    if (isMobile) {
      return (
        <Carousel label={carouselLabel}>
          {carouselEvents?.slice(0, 8).map(renderEvent)}
        </Carousel>
      )
    }

    return (
      <Carousel label={carouselLabel}>
        <EventsWrapper>
          {carouselEvents?.slice(0, 4).map(renderEvent)}
        </EventsWrapper>
        <EventsWrapper>
          {carouselEvents?.slice(4, 8).map(renderEvent)}
        </EventsWrapper>
        <EventsWrapper>
          {carouselEvents?.slice(8, 12).map(renderEvent)}
        </EventsWrapper>
      </Carousel>
    )
  }

  // if (!id) {
  //   console.log('nulling')
  //   return null
  // }

  const searchModal = useMemo(() => {
    if (showSearchModal) {
      return <SearchModal />
    }
  }, [showSearchModal])

  if (loading) {
    return (
      <div
        style={{
          height: '100vh',
          margin: 'auto',
          fontSize: '20px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <p>Loading</p>
        <div style={{ marginLeft: '10px' }}>
          <Loader />
        </div>
      </div>
    )
  }

  return (
    <Wrapper>
      <Banner
        data={{
          ...eventData,
          gallery: allImages
        }}
      />
      <Content>
        <RenderDetails handleOpenGallery={() => setShowGallery(true)} />
        {renderMoreLikeThis()}
      </Content>
      <MediaGallery
        show={showGallery}
        data={{ gallery: allImages }}
        handleClose={() => setShowGallery(false)}
      />
      {searchModal}
    </Wrapper>
  )
}

export default EventDetails
