import React, { useEffect, useRef, useState, useMemo } from 'react'
import {
  LayoutHeaderWrapper,
  HeaderContent,
  RcaLogo,
  ContentRight,
  SearchButton,
  PopoverContainer,
  PopoverButton,
  PopoverContent,
  PopoverTop,
  PopoverBottom,
  PopoverCloser,
  SearchCenter,
  SignupContentHeader
} from './styled'
import { Icon, Button, EventSearchBar } from 'components'
import { Link, useLocation } from 'react-router-dom'
import { useStoreon } from 'storeon/react'
import { logoutUser } from 'apis'
import { isFirstTimeVisitor } from 'utils'

const LayoutHeader = () => {
  const [hideHeader, setHideHeader] = useState(false)
  const [hideBg, setHideBg] = useState(true)
  const prevScroll = useRef(0)
  const { dispatch, isMobile, user, userData } = useStoreon(
    'user',
    'isMobile',
    'userData'
  )
  const { pathname } = useLocation()
  const [showHamburger, setShowHamburger] = useState(true)
  // const [showSearch, setShowSearch] = useState(true)

  const checkScroll = (e) => {
    const { scrollY } = window
    setHideHeader(scrollY > 0 && prevScroll.current < scrollY)
    setHideBg(scrollY === 0)
    prevScroll.current = window.scrollY
  }

  const handleLogout = async () => {
    await logoutUser()
    dispatch('setUserState', {
      user: null,
      userData: null
    })
  }

  useEffect(() => {
    if (pathname.includes('/signup') || pathname.includes('/login')) {
      if (pathname.includes('/signup/preferences/thankyou')) {
        setShowHamburger(true)
      } else {
        setShowHamburger(false)
      }
    } else {
      setShowHamburger(true)
    }
  }, [])

  const showMobileSearch = useMemo(() => {
    if (
      !pathname.includes('/signup') &&
      !pathname.includes('/login') &&
      !pathname.includes('/dashboard') &&
      !pathname.includes('/new-partner') &&
      !pathname.includes('/preferences')
    ) {
      return true
    } else {
      return false
    }
  }, [pathname, isMobile])

  const showSearch = useMemo(() => {
    if (
      pathname !== '/' &&
      pathname !== '/events' &&
      !pathname.includes('/signup') &&
      !pathname.includes('/login') &&
      !pathname.includes('/dashboard') &&
      !pathname.includes('/new-partner') &&
      !pathname.includes('/noresult') &&
      !pathname.includes('/somethingnew') &&
      !pathname.includes('/preferences') &&
      !pathname.includes('/artorganizations') &&
      !isMobile
    ) {
      return true
    } else {
      return false
    }
  }, [pathname, isMobile])

  useEffect(() => {
    window.addEventListener('scroll', checkScroll)

    return () => {
      window.removeEventListener('scroll', checkScroll)
    }
  }, [])

  const renderTop = () => {
    if (!user) {
      return (
        <PopoverTop>
          <div>
            <div>Create your account!</div>
            <div>
              Get personalized event recommendations tailored just for you!
            </div>
          </div>
          <div>
            <Button href="/signup">Sign up</Button>
            <Button href="/login">Log in</Button>
          </div>
        </PopoverTop>
      )
    }

    return (
      <PopoverTop loggedIn>
        <div>
          <div>Welcome back {userData?.firstName}!</div>
          <div>New arts experiences await you.</div>
        </div>
      </PopoverTop>
    )
  }

  return (
    <LayoutHeaderWrapper hide={hideHeader} hideBg={hideBg}>
      <HeaderContent>
        <Link to="/">
          <RcaLogo />
        </Link>
        {showSearch && (
          <SearchCenter>
            <EventSearchBar reroute />
          </SearchCenter>
        )}
        <ContentRight>
          {showMobileSearch && (
            <SearchButton onClick={() => {dispatch('setShowSearchModal', true)}}>
              <Icon name="search" />
            </SearchButton>
          )}

          {pathname === '/signup' && (
            <SignupContentHeader>
              <span>Already have an account?</span>
              <Button plain href="/login">
                Log in
              </Button>
            </SignupContentHeader>
          )}
          <PopoverContainer>
            {(!pathname.includes('/signup') ||
              pathname === '/signup/preferences/thankyou') &&
              !pathname.includes('/login') && (
                <PopoverButton>
                  <Icon name="menu" />
                </PopoverButton>
              )}

            <PopoverContent>
              {renderTop()}
              <PopoverBottom>
                <Link to="/events">
                  <PopoverCloser>
                    <Icon name="search" />
                    Search Events
                  </PopoverCloser>
                </Link>
                <Link to="/artorganizations">
                  <PopoverCloser>
                    <Icon name="heartIconLarge" />
                    Follow Art Organizations
                  </PopoverCloser>
                </Link>
                <Link to="/somethingnew">
                  <PopoverCloser>
                    <Icon name="starOutline" />
                    Explore Something New
                  </PopoverCloser>
                </Link>
                <Link to="/articles?c=latest">
                  <PopoverCloser>
                    <Icon name="megaPhone" />
                    Latest News
                  </PopoverCloser>
                </Link>
                <Link to="/episodes">
                  <PopoverCloser>
                    <Icon name="episodes" />
                    Watch
                  </PopoverCloser>
                </Link>
                {user && (
                  <Link to="/preferences">
                    <PopoverCloser>
                      <Icon name="gear" />
                      My Preferences
                    </PopoverCloser>
                  </Link>
                )}
                {userData?.isVenueOwner && userData?.venueSlug && (
                  <Link to={`/venue/${userData?.venueSlug}/dashboard/profile`}>
                    <PopoverCloser>
                      <Icon name="venue" />
                      My Organization
                    </PopoverCloser>
                  </Link>
                )}
                {user && (
                  <a onClick={handleLogout}>
                    <PopoverCloser>
                      <Icon name="exit" />
                      Sign-Out
                    </PopoverCloser>
                  </a>
                )}
              </PopoverBottom>
            </PopoverContent>
          </PopoverContainer>
        </ContentRight>
      </HeaderContent>
    </LayoutHeaderWrapper>
  )
}

export default LayoutHeader
